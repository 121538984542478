

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

export const SlideStruct = ({ key='', title='', component }) => {
    return {
        key,
        title,
        component
    }
}

const SLIDING_INTERVAL = 800

export default class Parallax extends Component {
    constructor (props) {
        super(props)
        this.state = {
            prevSlideKey: null,
            slideKey: props.activeSlideKey || _.get(props.slides, `0.key`),
            isWheeling: false
        }
        this._mounted = false
        this.lastTouchMoveEvent = null
    }
    
    componentDidMount () {
        this._mounted = true
    }

    componentDidUpdate (prevProps, prevState) {
        const { activeSlideKey: prevActiveSlideKey } = prevProps
        const { slideKey: prevSlideKey } = prevState

        const { activeSlideKey } = this.props
        const { slideKey } = this.state

        if (!_.isEqual(prevSlideKey, slideKey)) {
            this._onChangeSlide(slideKey)
        } else if (!_.isEqual(prevActiveSlideKey, activeSlideKey) && !_.isEqual(activeSlideKey, slideKey)) {
            this._slide(activeSlideKey)
        }
    }

    componentWillUnmount () {
        this._mounted = false
    }

    _onChangeSlide (newSlideKey) {
        const { slides, onChangeSlide } = this.props
        onChangeSlide(newSlideKey, _.find(slides, { key: newSlideKey }))
    }

    _slide (newSlideKey='') {
        const { slideKey } = this.state
        if (!_.isEqual(slideKey, newSlideKey)) {
            this.setState({
                prevSlideKey: slideKey,
                slideKey: newSlideKey
            })   
        }
    }

    _handleSlideUp () {
        const { slides } = this.props
        const { slideKey } = this.state
        const slideIndex = _.findIndex(slides, { key: slideKey })

        const nextSlide = _.get(slides, `${slideIndex + 1}`)
        if (!_.isNil(nextSlide)) {
            this.setState({ isWheeling: true }, () => {
                setTimeout(() => {
                    if (this._mounted) {
                        this.setState({ isWheeling: false })
                    }
                }, SLIDING_INTERVAL)
            })
            this._slide(nextSlide.key)
        }
    }

    _handleSlideDown () {
        const { slides } = this.props
        const { slideKey } = this.state
        const slideIndex = _.findIndex(slides, { key: slideKey })

        const nextSlide = _.get(slides, `${slideIndex - 1}`)
        if (!_.isNil(nextSlide)) {
            this.setState({ isWheeling: true }, () => {
                setTimeout(() => {
                    if (this._mounted) {
                        this.setState({ isWheeling: false })
                    }
                }, SLIDING_INTERVAL)
            })
            this._slide(nextSlide.key)
        }
    }

    render () {
        const { slides, shouldIgnoreWheel } = this.props
        const { prevSlideKey, slideKey, isWheeling } = this.state
        const prevSlideIndex = _.findIndex(slides, { key: prevSlideKey })
        const prevSlide = slides[prevSlideIndex]

        const slideIndex = _.findIndex(slides, { key: slideKey })
        const slide = slides[slideIndex]
        
        return (
            <div className='parallax' onWheel={(e) => { 
                if (!isWheeling && !shouldIgnoreWheel) {
                    if (e.deltaY > 50) {
                        this._handleSlideUp()
                    } else if (e.deltaY < -50) {
                        this._handleSlideDown()
                    }
                }
            }}
            onTouchMove={(e) => {
                if (!isWheeling && !shouldIgnoreWheel) {
                    const timestamp = _.get(e, 'timeStamp')
                    const touchClientY = _.get(e, `touches.0.clientY`)
                    if (!_.isNil(this.lastTouchMoveEvent)) {
                        const lastTouchMoveTimestmap = _.get(this.lastTouchMoveEvent, 'timeStamp')
                        const lastTouchMoveEventClientY = _.get(this.lastTouchMoveEvent, `touches.0.clientY`)
                        
                        if ( _.every([timestamp, touchClientY, lastTouchMoveTimestmap, lastTouchMoveEventClientY], v => !_.isNil(v)) && (timestamp - lastTouchMoveTimestmap < 20)) {
                            console.log('1111', touchClientY - lastTouchMoveEventClientY)
                            if (touchClientY - lastTouchMoveEventClientY > 8) {
                                this._handleSlideDown()
                            } else if (lastTouchMoveEventClientY - touchClientY > 8) {
                                this._handleSlideUp()
                            }
                        }
                    }
                    this.lastTouchMoveEvent = e
                }
            }}>
                {prevSlide && <div className='parallax--slide prev' key={prevSlide.key}>{prevSlide.component}</div>}
                {slide && <div className={'parallax--slide current' + (!_.isNil(prevSlide) ? (prevSlideIndex < slideIndex ? ' slide-up' : ' slide-down') : '')} key={slide.key}>{slide.component}</div>}
            </div>
        )
    }
}

Parallax.propTypes = {
    slides: PropTypes.array.isRequired,
    activeSlideKey: PropTypes.string,
    shouldIgnoreWheel: PropTypes.bool,
    onChangeSlide: PropTypes.func.isRequired
}

Parallax.defaultProps = {
    onChangeSlide: () => {}
}



