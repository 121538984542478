import React, { Component } from 'react'
import moment from 'moment'
import DisclaimerModal from '../terms/DisclaimerModal'

export default class Footer extends Component {
    constructor (props) {
        super(props)
        this.state = {
            shouldShowDisclaimerModal: false
        }
    }
    render () {
        const { shouldShowDisclaimerModal } = this.state
        return (
            <footer className='footer'>
                <div className='footer--main'>
                    <button className='footer--disclaimer-button' onClick={() => { this.setState({ shouldShowDisclaimerModal: true }) }}>{`Terms of Use`}</button>
                    <div className='footer--copyright'>
                        {`Copyright ©Antelope Technology ${moment().format('YYYY')}. `}
                        <br />
                        {`All Rights Reserved`}
                        </div>
                </div>
                {shouldShowDisclaimerModal && <DisclaimerModal onClickClose={() => { this.setState({ shouldShowDisclaimerModal: false }) }} />}
            </footer>
        )
    }
}