import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

import { RxCross1 } from 'react-icons/rx'

Modal.setAppElement('#root')

export default class DisclaimerModal extends Component {
    constructor (props) {
        super(props)
        this.handleCloseModal = this.handleCloseModal.bind(this)
    }

    handleCloseModal () {
        const { onClickClose } = this.props
        onClickClose()
    }

    render () {
        return (
            <Modal overlayClassName='disclaimer-modal--overlay'
                className='disclaimer-modal'
                isOpen
                shouldCloseOnOverlayClick
                onRequestClose={this.handleCloseModal}>
                <div className='disclaimer-modal--main horizontal-centered'>
                    <div className='disclaimer-modal--header'>
                        <div className='disclaimer-modal--title'>{`Terms of Use`}</div>
                        <button className='disclaimer-modal--close-button' 
                            onClick={this.handleCloseModal}><RxCross1 /></button>
                    </div>
                    <div className='disclaimer-modal--body'>
                        <p>{`The terms and conditions of use set forth below (the “Terms”) apply to all webpages on this website (collectively, the "Website"), and your use of any of the services provided through the Website. `}</p>
                        <p>
                            {`Please read these Terms of use carefully before visiting the Website. 
                            By using the Website, you acknowledge that you have read, understood and agreed to be bound by these Terms. 
                            If you do not agree to any of the Terms, you are not an authorized user of this Website. 
                            You may reject these terms by leaving the site at any time. 
                            Unauthorized use of this Website, including but not limited to misuse of posted information, is strictly prohibited. 
                            Please also note that the terms and conditions of these Terms of Use are in addition to any other agreements between you and Antelope Technology, including any customer agreements, and any other agreements that govern your use of products, services, content, and information available on the Website. `}
                        </p>
                        <p>
                            {`Antelope Technology Limited ("Antelope Technology" or "we", "us" or "our") reserves the right to change, modify, add or remove portions of the Terms at any time for any reason and in our sole discretion without notice. 
                            Your use of the Website is governed by the version of the Terms in effect on the date this website is accessed by you. 
                            If you use the website after the amended Terms, you will be deemed to have agreed to the Terms as amended. 
                            The information and materials on the Website may contain typographical errors or inaccuracies. 
                            Any dated information is published as of its date only, and Antelope Technology does not undertake any obligation or responsibility to update or amend any such information. 
                            Antelope Technology may discontinue or change any product or service described in or offered on the Website at any time without prior notice. 
                            You agree that Antelope Technology and its subsidiaries and affiliates will not be liable to you or to any third party for any such modification, suspension or discontinuance. `}
                        </p>
                        <h3>{`No Offer/Local Restrictions`}</h3>
                        <p>
                            {`Nothing contained in or on the Website constitutes investment, legal or tax advice. 
                            Neither the information contained in the Website constitutes a solicitation or offer by Antelope Technology or any entity managed directly or indirectly by Antelope Technology, to buy or offer, or recommendation, to acquire or dispose of any security, commodity, or investment or to engage in any other transaction. 
                            Decisions based on information contained on the Website are the sole responsibility of the visitor. `}
                        </p>
                        <p>
                            {`Antelope Technology offers a number of products and services designed specifically for various categories of investors in various countries and regions. 
                            Not all products will be available to all investors. 
                            These products or services are only offered to such investors, in those countries and regions, in accordance with applicable laws and regulations. 
                            The information provided on the Website is not intended for distribution to, or use by, any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation. 
                            All persons and entities accessing the Website do so of their own initiative and are responsible for compliance with applicable local laws and regulations. 
                            The Website is not directed to any person in any jurisdiction where the publication or availability of the Website is prohibited, by reason of that person's nationality, residence or otherwise. 
                            Persons subject to these restrictions must not access the Website. `}
                        </p>
                        <p>
                            {`No investment products are offered or sold in any jurisdiction, or in any manner, in which an investment would be contrary to the applicable securities laws or any other local laws or regulations. 
                            Applications from persons who do not meet applicable eligibility or suitability requirements will be refused. 
                            In no event Antelope Technology shall be responsible or liable for loss or damage of any kind whatsoever arising directly or indirectly as a result of the content and information on this site. `}
                        </p>
                        <h3>{`Authorized User`}</h3>
                        <p>
                            {`Some of our services, and certain pages of the Website, are available only to clients or users who have been authorized by us to access those services and web pages. `}
                            {`Such authorization may require completion of an accredited investor questionnaire and satisfactory background information screening. `}
                        </p>
                        <p>
                            {`Unauthorized use of the Website and/or our systems, including, but not limited to, unauthorized entry into and/or any attempted access of Antelope Technology’s systems and/or any restricted areas of any of the Website, misuse or sharing of passwords or misuse of any other information, is strictly prohibited. `}
                        </p>
                        <h3>{`Copyright and Trademarks`}</h3>
                        <p>
                            {`All copyright, trademarks, service marks, trade names, and logos displayed on this Website (collectively referred to as the "Marks") are proprietary to Antelope Technology or their respective owners. 
                            Any use of the Marks, or any other Marks owned by or licensed to Antelope Technology without Antelope Technology's express written consent, is strictly prohibited. `}
                        </p>
                        <h3>{`No Warranty, Limitation of Liability`}</h3>
                        <p>
                            {`THE INFORMATION ON THE WEBSITE IS PROVIDED "AS IS". 
                            ANTELOPE TECHNOLOGY DOES NOT WARRANT THE ACCURACY OF THE MATERIALS PROVIDED HEREIN (EITHER EXPRESSLY OR IMPLIEDLY) FOR ANY PARTICULAR PURPOSE AND EXPRESSLY DISCLAIMS ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. `}
                        </p>
                        <p>
                            {`YOU ACKNOWLEDGE THAT ANTELOPE TECHNOLOGY SHALL HAVE NO LIABILITY, CONTINGENT OR OTHERWISE, TO YOU OR TO ANY THIRD PARTIES, OR ANY RESPONSIBILITY WHATSOEVER, FOR THE FAILURE OF ANY CONNECTION OR COMMUNICATION SERVICE, TO PROVIDE OR MAINTAIN YOUR ACCESS TO ONLINE SERVICES OR PRODUCTS, OR FOR ANY INTERRUPTION OR DISRUPTION OF SUCH ACCESS OR ANY ERRONEOUS COMMUNICATION BETWEEN ANTELOPE TECHNOLOGY AND YOU. 
                            ANTELOPE TECHNOLOGY SHALL HAVE NO LIABILITY, CONTINGENT OR OTHERWISE, TO YOU OR TO THIRD PARTIES, FOR THE ACCURACY, QUALITY, TIMELINESS, PERFORMANCE, RELIABILITY, OR COMPLETENESS OF THE INFORMATION OR SERVICES CONTAINED ON THE WEBSITE, OR DELAYS, OMISSIONS, OR INTERRUPTIONS IN THE DELIVERY OF THE DATA OR SERVICES OR PRODUCTS AVAILABLE ON THIS WEBSITE OR FOR ANY OTHER ASPECT OF THE PERFORMANCE OF THESE SERVICES AND PRODUCTS. 
                            IN NO EVENT WILL ANTELOPE TECHNOLOGY BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES THAT MAY BE INCURRED OR EXPERIENCED ON ACCOUNT OF THE USE OF ANY DATA OR SERVICES OR PRODUCTS MADE AVAILABLE ON THIS WEBSITE, EVEN IF ANTELOPE TECHNOLOGY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 
                            ANTELOPE TECHNOLOGY SHALL HAVE NO RESPONSIBILITY TO INFORM OR NOTIFY YOU OF ANY DIFFICULTIES EXPERIENCED BY ANTELOPE TECHNOLOGY OR ANY THIRD PARTIES WITH RESPECT TO THE USE OF THE SERVICES, PRODUCTS OR DATA PROVIDED HEREIN. `}
                        </p>
                        <p>
                            {`YOU FURTHER ACKNOWLEDGE THAT NOTHING CONTAINED ON THIS WEBSITE (INCLUDING, BUT NOT LIMITED TO, STRATEGIES AND RESEARCH, DAILY WIRES, AND EDUCATIONAL ARTICLES) CONSTITUTES INVESTMENT, LEGAL, TAX OR OTHER ADVICE, NOR IS IT TO BE RELIED UPON IN MAKING ANY INVESTMENT OR OTHER DECISIONS. 
                            YOU SHOULD SEEK PROFESSIONAL ADVICE PRIOR TO MAKING ANY INVESTMENT DECISIONS. `}
                        </p>
                        <h3>{`Severability`}</h3>
                        <p>
                            {`If any of the provisions of these Terms are deemed unlawful or for any reason unenforceable, the same shall be inoperative only to the extent necessary to achieve compliance with applicable law and shall not affect the remaining Terms, which shall be given full effect, without regard to the invalid portion(s).`}
                        </p>
                    </div>
                </div>
            </Modal>
        )
    }
}

DisclaimerModal.propTypes = {
    onClickClose: PropTypes.func.isRequired
}

DisclaimerModal.defaultProps = {
    onClickClose: () => {}
}